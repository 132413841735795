.cookiePolicy-mainContainer {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background: rgba(206, 225, 241, 1);
    padding: 30px 100px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cookiePolicy-mainText {
    font: 400 14px/22px "Montserrat";
    color: #1a2d45;
    padding: 10px 0px;
    /* display: flex;
    align-items: center; */
}

.cookiePolicy__btn {
    width: 105px;
    height: 40px;
    color: #fff;
    background: #3b7dc1;
    border-radius: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font: 600 14px/22px "Montserrat";
}

.popup__close--blue {
    width: 40px;
    height: 40px;
    border: 2px solid rgba(59, 125, 193, 0.3);
    border-radius: 40px;
    background: url("../../../img/close-blue.svg") center center no-repeat;
    /* position: absolute;
    top: 36px;
    right: 36px; */
    z-index: 2;
    cursor: pointer;
}

.cookiePolicy_btnsContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.cookiePolicy__openModal {
    color: #3b7dc1;
    text-decoration: underline;
    cursor: pointer;
}

.cookiePolicy--hide {
    display: none;
}

.cookiesPolicy-mainContent {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

@media (max-width: 1150.98px) {
    .cookiePolicy-mainContainer {
        padding: 25px 20px;
    }
    .cookiesPolicy-mainContent {
        gap: 20px;
    }
}

@media (max-width: 1081.98px) {
    .cookiePolicy-mainText {
        padding: 0px;
    }
}

@media (max-width: 510.98px) {
    .cookiesPolicy-mainContent {
        gap: 10px;
    }

    .cookiePolicy_btnsContainer {
        flex-direction: column;
        justify-content: center;
    }

    .cookiePolicy__btn {
        width: 90px;
        height: 30px;
        font: 600 13px/21px "Montserrat";
    }
}
