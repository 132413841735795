.update-duelsInfo__title {
    margin-bottom: 32px;
    font: bold 32px/40px "Montserrat";
    color: #23262f;
}

.update-duelsInfo__textarea {
    margin-bottom: 12px;
}

.update-duelsInfo__error {
    margin-bottom: 25px;
}

.manager-user__error,
.update-duelsInfo__select {
    margin-bottom: 32px;
}

@media (max-width: 1399.98px) {
}

@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
    .update-duelsInfo__title {
        margin-bottom: 25px;
        padding-right: 40px;
        font: bold 27px/30px "Montserrat";
    }

    .update-duelsInfo__error,
    .update-duelsInfo__select {
        margin-bottom: 25px;
    }
}

@media (max-width: 575.98px) {
    .update-duelsInfo__title {
        margin-bottom: 20px;
        font: bold 23px/30px "Montserrat";
    }

    .update-duelsInfo__error,
    .update-duelsInfo__select {
        margin-bottom: 20px;
    }
}
