/* TaskInfo */

.task-info {
    /* height: 100%; */
    /* max-height: 100%; */
}

.task-info__title {
    margin-bottom: 32px;
    font: bold 30px/40px "Montserrat";
    color: #fff;
}

.task-info__roles {
    display: flex;
    margin-bottom: 10px;
    color: #fff;
    height: 100%;
    max-height: 300px;
    overflow-y: auto;
    scrollbar-color: #a6cbe0 #f2f5ff;
    scrollbar-width: thin;
    scroll-margin: 400px;
    padding-right: 14px;
}

.popup__close {
    background: url("../../img/close-white.svg") center center no-repeat !important;
}

.task-info__roles::-webkit-scrollbar {
    width: 6px;
}
.task-info__roles::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
}

.task-info__roles::-webkit-scrollbar-thumb {
    background-color: #a6cbe0;
    border-radius: 5px;
}

.scroll {
    min-height: 150px;
    height: 100%;
}

.task-info__role {
    padding: 8px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font: 400 14px/14px "Montserrat";
    color: #777e90;
    user-select: none;
}

.task-info__role {
    margin: 0 10px 10px 0;
}

.task-info__role:last-child {
    margin-right: 0;
}

.task-info__description {
    color: #777e90;
}

.boldText {
    color: #000;
    font-weight: 500;
}

ol {
    counter-reset: section;
    list-style-type: none;
}

ol > li {
    counter-increment: section;
    margin-bottom: 5px;
}

ol > li::before {
    content: counters(section, ".") " ";
}

ol ol {
    counter-reset: subsection;
    list-style-type: none;
    padding-left: 15px;
}

ol ol > li {
    counter-increment: subsection;
    margin-bottom: 3px;
}

ol ol > li::before {
    content: counters(section, ".") "." counters(subsection, ".") " ";
}

.scroll > ol > li {
    margin-bottom: 25px;
}

.scroll ol {
    padding-left: 15px;
}

.scroll > ol > li > span {
    display: inline-block;
    margin-bottom: 10px;
}

.link {
    color: blue;
}
/*
 * Responsive
 * https://getbootstrap.com/docs/5.1/layout/breakpoints/#max-width
*/
@media (max-width: 1399.98px) {
}

@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
    .task-info__title {
        margin-bottom: 25px;
        padding-right: 40px;
        font: bold 27px/30px "Montserrat";
    }
}

@media (max-width: 575.98px) {
    .task-info__title {
        margin-bottom: 20px;
        font: bold 23px/30px "Montserrat";
    }
    .task-info__roles {
        max-height: 200px;
    }

    .task-info__role {
        padding: 6px;
        font-size: 13px;
    }
}

@media (max-height: 435.98px) {
    .task-info__roles {
        max-height: 150px;
    }
}
