/* SendComplaint */

.delete-group {
    /* background-color: #1A2D45; */
}

.delete-group__title {
    margin-bottom: 32px;
    font: 700 24px/34px "Montserrat";
    width: 75%;
    color: #000;
}

.delete-group__divider {
    width: 100%;
    border-bottom: 1px solid #48494b;
    margin: 32px auto;
}

/*
 * Responsive
 * https://getbootstrap.com/docs/5.1/layout/breakpoints/#max-width
*/
@media (max-width: 1399.98px) {
}

@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
    .delete-group__title {
        margin-bottom: 25px;
        padding-right: 40px;
        font: bold 27px/30px "Montserrat";
    }
}

@media (max-width: 575.98px) {
    .delete-group__title {
        margin-bottom: 20px;
        font: bold 20px/30px "Montserrat";
        padding-right: 0px;
    }
}
