* {
    box-sizing: border-box;
}

html {
    width: 100%;
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    font-family: "Montserrat";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 20px;
    color: #777e90;
}

#root,
.App {
    height: 100%;
}

.preloader {
    background: url("./img/preloader.svg") center center no-repeat rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(43px);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

/*Table*/
.table-responsive {
    display: block;
    width: 100%;
    height: 100%;
    /* overflow-x: auto; */
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.table th {
    padding: 20px;
    background: rgba(233, 246, 253, 1);
    font: 700 11px/16px "Montserrat";
    color: rgba(26, 45, 69, 0.7);
    text-align: left;
    text-transform: uppercase;
    border-top: 1px solid #e6e8ec;
}

.table th:first-child {
    border-left: 1px solid #e6e8ec;
    border-top-left-radius: 8px;
}

.table th:last-child {
    border-right: 1px solid #e6e8ec;
    border-top-right-radius: 8px;
}

.table td {
    padding: 15px 20px;
    border-bottom: 1px solid #e6e8ec;
    vertical-align: top;
    color: #1a2d45;
}

.table td:first-child {
    border-left: 1px solid #e6e8ec;
}

.table td:last-child {
    border-right: 1px solid #e6e8ec;
}

.table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
}

.table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
}

.table .nowrap {
    white-space: nowrap;
}

/*Mobile table*/
.mobile-table__item {
    padding: 25px;
    border: 1px solid #e6e8ec;
    border-top: 0;
    position: relative;
}

.mobile-table__item:first-child {
    border-top: 1px solid #e6e8ec;
    border-radius: 7px 7px 0 0;
}

.mobile-table__item:last-child {
    border-radius: 0 0 7px 7px;
}

.mobile-table__row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.mobile-table__row:last-child {
    margin-bottom: 0;
}

.mobile-table__col {
    width: 50%;
    display: flex;
    gap: 10px;
}

.mobile-table__col:last-child {
    padding-right: 40px;
}

.mobile-table__title {
    width: 35%;
    font: 400 12px/16px "Montserrat";
    color: #777e90;
    word-break: break-word;
    white-space: nowrap;
}

.mobile-table__value {
    width: 65%;
    font: 400 14px/16px "Montserrat";
    color: #23262f;
    word-break: break-word;
}

.mobile-table__menu {
    position: absolute;
    top: 20px;
    right: 20px;
}

.mobile-table__menu-button {
    width: 32px;
    height: 32px;
    background: url("./img/dropmenu.svg") center center no-repeat #f4f5f6;
    border-radius: 32px;
}

.mobile-table__menu-button--active {
    width: 32px;
    height: 32px;
    background: url("./img/dropmenu_active.svg") center center no-repeat #f4f5f6;
    border-radius: 32px;
}

.mobile-table__menu .dropmenu {
    top: 40px;
}

.mobile-table__row--full .mobile-table__col {
    width: 100%;
}

.mobile-table__row--full .mobile-table__title {
    width: 18%;
}

.mobile-table__row--full .mobile-table__value {
    width: 83%;
}

/*Radio*/
input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.radiobox {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    vertical-align: middle;
    cursor: pointer;
    background-image: url("./img/radio-sprite.svg#normal");
    background-position: center center;
    background-repeat: no-repeat;
}

input[type="radio"]:hover ~ .radiobox {
    background-image: url("./img/radio-sprite.svg#hover");
}

input[type="radio"]:checked ~ .radiobox {
    background-image: url("./img/radio-sprite.svg#checked");
}

input[type="radio"]:checked:hover ~ .radiobox {
    background-image: url("./img/radio-sprite.svg#checked-hover");
}

input[type="radio"]:disabled ~ .radiobox {
    background-image: url("./img/radio-sprite.svg#disabled");
    cursor: default;
}

input[type="radio"]:checked:disabled ~ .radiobox {
    background-image: url("./img/radio-sprite.svg#checked-disabled");
    cursor: default;
}

.material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
}

/*Typography HTML*/
.typography {
    color: #23262f;
}

.typography h1 {
    margin-bottom: 20px;
    font: 600 24px/33px "Montserrat";
    color: #23262f;
}

.typography h2 {
    margin-bottom: 20px;
    font: 600 18px/25px "Montserrat";
    color: #23262f;
}

.typography h3 {
    margin-bottom: 15px;
    font: 600 16px/20px "Montserrat";
    color: #23262f;
}

.typography b,
.typography strong {
    font-weight: bold;
}

.typography i,
.typography em {
    font-style: italic;
}

.typography a {
    color: #3b7dc1;
}

.typography a:hover {
    text-decoration: none;
}

.typography p {
    margin: 20px 0;
}

.typography blockquote {
    margin: 20px 0;
    padding: 30px;
    background: #f4f5f6;
    border-radius: 7px;
    border-left: 6px solid #3b7dc1;
}

.typography ol {
    margin: 20px 0;
    padding: 0;
    list-style: none;
    counter-reset: item;
}

.typography ol li {
    margin: 10px 0;
    padding-left: 30px;
    background: none;
    counter-increment: item;
}

.typography ol li:before {
    display: inline-block;
    content: counters(item, ".");
    margin-left: -25px;
    margin-right: 12px;
    padding: 6px;
    text-align: center;
    background: rgba(55, 114, 255, 0.3);
    border-radius: 16px;
    font: 600 12px/7px "Montserrat";
    color: #3b7dc1;
}

.typography ol ol {
    margin: 0;
}

.typography ul {
    margin: 20px 0;
    padding: 0;
    list-style: none;
}

.typography ul li {
    margin: 11px 0;
    padding-left: 30px;
    position: relative;
}

.typography ul li::before {
    content: "•";
    display: block;
    width: 12px;
    height: 14px;
    font: bold 25px/18px "Montserrat";
    color: #3b7dc1;
    position: absolute;
    left: 10px;
    top: 1px;
}

.typography ul ul {
    margin: 0;
}

/*
 * Responsive
 * https://getbootstrap.com/docs/5.1/layout/breakpoints/#max-width
*/
@media (max-width: 1399.98px) {
}

@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
}

@media (max-width: 650.98px) {
    .mobile-table__item {
        padding: 15px;
    }
    .mobile-table__title {
        width: 40%;
    }
}

@media (max-width: 650.98px) {
    body {
        font-size: 13px;
        line-height: 18px;
    }

    .mobile-table__item {
        padding: 20px 15px;
        margin: 0 -15px;
        border-left: 0;
        border-right: 0;
    }

    .mobile-table__item:first-child,
    .mobile-table__item:last-child {
        border-radius: 0;
    }

    .mobile-table__col {
        flex-wrap: wrap;
        gap: 5px;
    }

    .mobile-table__title,
    .mobile-table__value,
    .mobile-table__row--full .mobile-table__title,
    .mobile-table__row--full .mobile-table__value {
        width: 100%;
    }
}
