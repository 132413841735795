/* Input */

.input {
}

.input__title {
    margin-bottom: 10px;
    font: 700 11px/16px "Montserrat";
    color: rgb(26, 45, 69, 0.7);
    text-transform: uppercase;
}

.input__wrap {
    display: flex;
    border: 2px solid #e6e8ec;
    box-sizing: border-box;
    border-radius: 12px;
}

.input__input {
    flex-grow: 1;
    width: 100%;
    padding: 12px 16px 13px;
    font: 400 14px/14px "Montserrat";
    color: #000000;
    border: 0;
    outline: none;
    background: none;
    border-radius: 12px;
}

.input__input::placeholder {
    font: 400 14px/22px "Montserrat";
    color: #1a2d45;
}

/* input::-webkit-calendar-picker-indicator {
    opacity: 0;
} */

.input--focused .input__wrap {
    border-color: #8e3659;
}

.input--error .input__wrap {
    border-color: #ff4c77;
}

.input--error .input__input {
    color: #ff4c77;
}

.input__email {
    width: 64px;
    height: 48px;
    background: url("../../../img/input-email.svg") center center no-repeat;
}

.input__date {
    width: 64px;
    height: 48px;
    background: url("../../../img/input-date.svg") center center no-repeat;
}

.input__icon-text {
    font: 400 12px/17px "Montserrat";
    color: rgba(26, 45, 69, 0.7);
    padding: 12px 16px;
}
/*
 * Responsive
 * https://getbootstrap.com/docs/5.1/layout/breakpoints/#max-width
*/
@media (max-width: 1399.98px) {
}

@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
    .input__input {
        padding: 11px 16px 12px;
    }
}

@media (max-width: 575.98px) {
}
